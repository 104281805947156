import * as React from "react";
import styled from "styled-components";
import { Parallax } from "react-parallax";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Routes, Route } from "react-router-dom";
import Typography from "@mui/material/Typography";

import "./App.css";
import ContactUs from "./ContactUs";
import Banner from "./common/Banner";
import Gallery from "./Gallery";
import ResponsiveAppBar from "./common/ResponsiveAppBar";

import banner from "./banner.jpg";
import Events from "./Events";
import Home from "./Home";

const FooterContainer = styled.div`
  position: absolute;
  width: 100% !important;
  height: 45px !important;
  background: #115107;
  color: white;
`;

const backgroundStyle = {
    minHeight: 'calc(100vh - 100px)',
};

function App() {
    return (
        <div>
            <ResponsiveAppBar/>
            <Parallax bgImage={banner} strength={500} style={backgroundStyle}>
                <Grid container spacing={0} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Banner/>
                    </Grid>
                    <Grid style={{paddingBottom: '25px'}} item xs={12}/>
					<Grid item xs={12}>
						<Routes>
							<Route path={"/"} element={<Home/>}/>
							<Route path={"/gallery"} element={<Gallery/>}/>
							<Route path={"/events"} element={<Events/>}/>
							<Route path={"/contact"} element={<ContactUs/>}/>
						</Routes>
					</Grid>
					<Grid style={{paddingBottom: '25px'}} item xs={12}/>
                </Grid>
            </Parallax>
            <FooterContainer>
                <Box m={1}>
                    <Typography>© {new Date().getFullYear()} Kearsarge Trail Snails</Typography>
                </Box>
            </FooterContainer>
        </div>
    );
}

export default App;
