import InnerHTML from 'dangerously-set-html-content'

function PaypalButton() {
    let html = `
        <div id="donate-button-container">
            <div id="donate-button" onmouseover="onMouse(this)" onmouseout="outMouse(this)"></div>
            <script src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js" charset="UTF-8"></script>
            <script>

                function onMouse(x) {
                    x.style.opacity = ".8";
                }
              
                function outMouse(x) {
                    x.style.opacity = "1";
                }

                PayPal.Donation.Button({
                    env:'production',
                    hosted_button_id:'2E2ML93LWFXVY',
                    image: {
                        src:'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
                        alt:'Donate with PayPal button',
                        title:'PayPal - The safer, easier way to pay online!',
                }
                }).render('#donate-button');
            </script>
        </div>
    `

    return (
      <InnerHTML html={html}/>
    )
}
export default PaypalButton;