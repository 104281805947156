import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#115107',
        },
        secondary: {
            main: '#ffffff',
        },
    },
	typography: {
		fontFamily: [
			"NonBreakingSpaceOverride",
			"Hoefler Text",
			"Garamond",
			"Times New Roman", 
			"serif"
		].join(","),
		fontSize: 16,
	}
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router>
                <App/>
            </Router>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
