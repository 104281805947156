import React, { useState, useEffect } from 'react';

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

import axios from "axios";
import Parser from 'html-react-parser';

export default function Home() {

    const [homeText, setHomeText] = useState("Monthly meetings on the 2nd Thursday of the month at 7 PM at Jason's barn.");
	
	const getHomeText = () => {
			axios.get(`/api/homeTexts/?format=json`)
				.then(response => setHomeText(response.data.length === 1 ? response.data[0].message : ''))
				.catch(error => console.log(error));
	};
	
	useEffect(() => {
		getHomeText();
	}, []);

    return (
        <Box sx={{ lineHeight: 0 }} >
            <Grid container spacing={2} justifyContent={"center"} textAlign={"center"}>
                <Grid item xs={11} md={8} lg={7}/>
                <Grid item xs={11} md={8} lg={7}>
                    <Card>
                        <CardHeader title={ <Typography variant={"h4"}>Kearsarge Trail Snails</Typography> }/>
                        <CardContent>
                            <Typography fontSize={20}>
                                Kearsarge Trail Snails, Inc., is a nonprofit organization whose mission is to advance the general welfare and safety of snowmobilers and landowners. Since 1972, we have developed a fraternal spirit among snowmobilers and other trail enthusiasts to provide a medium for the exchange of snowmobile information. Our goal is to promote all desirable and lawful functions for the successful operation of the club and to further the public interest in snowmobiling and other trail uses.
                            </Typography>
                            <Typography fontSize={20}>
                                <br/>
                                Join our club by registering <a href="https://slednh.tfaforms.net/5075630?tfa_9=001Do00000I52ixIAB" target="_blank" rel="noopener noreferrer">here</a>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={11} md={8} lg={7}>
                    <Card>
                        <CardContent>
                            <Typography fontSize={20}>
                                { Parser(homeText) }
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={11} md={8} lg={7}>
                    <Card>
                        <CardContent>
                        <Typography fontSize={20}>
                            Missed a meeting? Follow the links below to keep up to date with the club's activities!
                        </Typography>
                            <iframe
                                src="https://drive.google.com/embeddedfolderview?id=1-jVPdfTvJPtSacc97rj445-lieeArk59#list"
                                style={{ border: 'none', width: '100%', height: '175px' }}></iframe>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};