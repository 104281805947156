import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ReCAPTCHA from "react-google-recaptcha"

export default function ContactUs() {
	
	const form = useRef();
	const [sent, setSent] = useState(false);
	const [capcha, setCapcha] = useState('');

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_ng9c73o', 'template_5007kvt', form.current, '-7tnfaGbdkkCs2QHl')
		  .then((result) => {
			  setSent(true);
		  }, (error) => {
			  console.log(error.text);
		  });
	  };
    return (
        <Grid container spacing={2} justifyContent={"center"}>
			{sent && <Grid item xs={11} md={8} lg={7} container justifyContent="center">
                <Grid item xs={12}>
                    <Paper style={{ background: '#115107', padding: '10px'}}>
                        <Typography color={"secondary"} align={"center"}>Successfully sent email!</Typography>
                    </Paper>
                </Grid>
            </Grid>}
            <Grid item xs={12}/>
            <Grid item xs={11} md={8} lg={7}>
                <Paper>
                    <form ref={form} name={"email"} onSubmit={sendEmail}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}/>
                            <Grid item xs={5}>
                                <TextField label={"Name"} variant={"outlined"} size={"small"} name={"user_name"}
                                           required fullWidth disabled={sent}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label={"Email"} variant={"outlined"} size={"small"} required fullWidth
                                           name={"user_email"} disabled={sent}/>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField label={"Message"} variant={"outlined"} multiline minRows={5} maxRows={15}
                                           fullWidth size={"small"} required name={"user_message"} disabled={sent}/>
                            </Grid>
                            <Grid item xs={11}>
                                <ReCAPTCHA sitekey={"6Lfjip4fAAAAAOerxs64KNiwr1-WlhStMgngd-ND"}
                                           onChange={val => setCapcha(val)} required/>
                            </Grid>
                            <Grid item xs={11}>
                                <Button variant={"contained"} size={"small"} type={"submit"}
                                        disabled={!capcha || sent}>Submit</Button>
                            </Grid>
                            <Grid item xs={12}/>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={11} md={8} lg={7}>
                <Paper>
                    <Grid container spacing={2} justifyContent={"center"} textAlign={"center"}>
                        <Grid item>
                            <Typography>Kearsarge Trail Snails Inc</Typography>
                            <Typography>PO Box 97</Typography>
                            <Typography>Warner, NH 03278</Typography>
                        </Grid>
                        <Grid item xs={12}/>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};