import * as React from "react";
import { NavLink } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import snaily from "../snaily.png";
import PaypalButton from "./PaypalButton";

const appBarStyle = {
    maxHeight: '60px',
};

const gridItemStyle = {
    paddingTop: '15px',
};

const iconStyle = {
    width: '55px'
};

const paypalItemStyle = {
    position: 'absolute',
    right: '25px',
    paddingBottom: '2px'
};

export default function ResponsiveAppBar() {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position={"static"} style={appBarStyle}>
            <Toolbar>
                <Box component={"span"} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Grid container spacing={2}>
                        <Grid item style={gridItemStyle}>
                            <img src={snaily} style={iconStyle} alt={"Snaily"}/>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <NavLink to="/" className={"navLink"} activeclassname={"navLinkActive"}>
                                <Typography variant={"h6"}>
                                    Home
                                </Typography>
                            </NavLink>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <NavLink to="/gallery" className={"navLink"} activeclassname={"navLinkActive"}>
                                <Typography variant={"h6"}>
                                    Gallery
                                </Typography>
                            </NavLink>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <NavLink to="/events" className={"navLink"} activeclassname={"navLinkActive"}>
                                <Typography variant={"h6"}>
                                    Events
                                </Typography>
                            </NavLink>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <NavLink to="/contact" className={"navLink"} activeclassname={"navLinkActive"}>
                                <Typography variant={"h6"}>
                                    Contact
                                </Typography>
                            </NavLink>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <Box component={"span"} sx={{ display: { xs: 'block', sm: 'none' } }}>block</Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={"span"} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Grid container spacing={2}>
                        <Grid item style={gridItemStyle}>
                            <IconButton
                                size={"small"}
                                edge={"start"}
                                color={"inherit"}
                                aria-label={"Home"}
                                onClick={handleClick}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id={"basic-menu"}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <NavLink to="/" className={"navLink"} activeclassname={"navLinkActive"}>
                                        <Typography color={"primary"} variant={"title"}>
                                            Home
                                        </Typography>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <NavLink to="/gallery" className={"navLink"} activeclassname={"navLinkActive"}>
                                        <Typography color={"primary"} variant={"title"}>
                                            Gallery
                                        </Typography>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <NavLink to="/events" className={"navLink"} activeclassname={"navLinkActive"}>
                                        <Typography color={"primary"} variant={"title"}>
                                            Events
                                        </Typography>
                                    </NavLink>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <NavLink to="/contact" className={"navLink"} activeclassname={"navLinkActive"}>
                                        <Typography color={"primary"} variant={"title"}>
                                            Contact
                                        </Typography>
                                    </NavLink>
                                </MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item style={gridItemStyle}>
                            <Typography color={"secondary"} variant={"h6"}>
                                KTS
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <div style={paypalItemStyle}>
                    <PaypalButton/> 
                </div>
            </Toolbar>
        </AppBar>
    );
};