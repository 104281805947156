import React, { useState, useEffect } from 'react';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

import axios from "axios";
import Parser from 'html-react-parser';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default function Events() {

    const defaultEvent = {
        id: 'No Events Scheduled',
        title: 'No Events Scheduled',
        description: 'Please check back for future events!',
        start: new Date(),
        trueDate: new Date(),
        allDay: true
    }

    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);

    const handleEventClick = ({ event }) => {
        setEvent({
            id: event.id,
            title: event.title,
            description: event.extendedProps.description,
            start: event.start,
            trueDate: event.extendedProps.trueDate,
            allDay: true
        })
    }

	const getEvents = () => {
			axios.get(`/api/events/?format=json`)
				.then(response => {

                    let newEvents = response.data.map(event => {
                        return {
                            id: event.title + event.date,
                            title: event.title,
                            description: event.description,
                            start: event.date,
                            trueDate: event.date,
                            allDay: true
                        }
                    }).sort((a, b) => new Date(a.trueDate) - new Date(b.trueDate));

                    setEvents(newEvents)

                    if(newEvents.length > 0) {
                        // Grab the closest future date
                        let currentDate = new Date()
                        let closestEvent = null

                        for(let e in newEvents) {
                            if(new Date(newEvents[e].trueDate) < currentDate) {
                                continue
                            }

                            if(closestEvent == null || new Date(newEvents[e].trueDate) < new Date(closestEvent.trueDate)) {
                                closestEvent = newEvents[e]
                                continue
                            }
                        }

                        if(closestEvent == null) {
                            setEvent(defaultEvent)
                        } else {
                            setEvent(closestEvent)
                        }
                    } else {
                        setEvent(defaultEvent)
                    }
                })
				.catch(error => {
                    console.log(error)
                    setEvent(defaultEvent)
                });
	};

	useEffect(() => {
		getEvents();
	}, []);

    const getPost = (post) => {
        return (post != null) ? (
            <Grid item xs={11} md={8} lg={7} key={post.id}>
                <Card variant='elevation'>
                    <CardHeader
                        title={<Typography variant={"h5"}>{post.title}</Typography>}
                        subheader={
                            <Typography
                                variant={"subtitle2"}>{post.trueDate ? new Date(post.trueDate).toLocaleString() : ''}</Typography>
                        }/>
                    <CardContent>
                        <Typography fontSize={22} paragraph>
                            {Parser(post.description)}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>) : (
            <Grid item xs={11} md={8} lg={7} key={"nokey"}>
                <Card variant='elevation'>
                    <CardHeader
                        title={<Typography variant={"h5"}>{""}</Typography>}
                        subheader={
                        <Typography
                            variant={"subtitle2"}>{""}</Typography>
                    }/>
                    <CardContent>
                        <Typography fontSize={22} paragraph>
                            <br/>
                            <br/>
                            <br/>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>)

    }

    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);

    return (
        <Box sx={{ lineHeight: 0 }} >
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={11} md={8} lg={7}/>
                { getPost(event) }
                <Grid item xs={11} md={8} lg={7}>
                    <Card variant='elevation' >
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={
                                isMobile ?
                                    {
                                        center: 'title',
                                        right: '',
                                        left: ''
                                    } :
                                    {
                                        center: '',
                                        right: 'today prev,next',
                                        left: 'title'
                                    }
                            }
                            initialView={isMobile ? 'timeGridOneDay' : 'dayGridMonth'}
                            views= {
                                {
                                    'timeGridOneDay': {
                                        type: 'timeGrid',
                                        duration: {days: 1}
                                    }
                                }
                            }
                            editable={false}
                            selectable={false}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            events={events}
                            eventClick={handleEventClick}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};