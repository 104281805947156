import React, { useState, useEffect } from 'react';

import Grid from "@mui/material/Grid";

import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";
import Paper from "@mui/material/Paper";

import axios from "axios";

export default function Gallery() {
			
	const [images, setImages] = useState([]);
	
	const getBanner = () => {
		axios.get(`/api/images/?format=json`)
				.then(response => setImages(response.data.map(image => {
					return { original: image.gallery_img, thumbnail: image.gallery_img };
				})))
				.catch(error => console.log(error));
	};
	
	useEffect(() => {
		getBanner();
	}, []);
	
    return (
        <Grid container spacing={2} justifyContent={"center"}>
			<Grid item xs={11} md={8} lg={7}/>
            <Grid item xs={11} md={8} lg={7}>
                <Paper>
                    {images.length > 0 && <ImageGallery items={images} lazyLoad/>}
                </Paper>
            </Grid>
        </Grid>
    );
};