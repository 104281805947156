import React, { useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import axios from "axios";
import Parser from 'html-react-parser';

const bannerTextStyle = {
    padding: '10px',
    background: '#67676799',
};

export default function Banner() {
	
	const [banner, setBanner] = useState('');
	
	const getBanner = () => {
			axios.get(`/api/banners/?format=json`)
				.then(response => setBanner(response.data.length === 1 ? response.data[0].message : ''))
				.catch(error => console.log(error));
	};
	
	useEffect(() => {
		getBanner();
	}, []);
	
    return banner && 
			<Paper style={bannerTextStyle}>
				<Typography align={"center"} style={{ color: 'white'}}>
					{ Parser(banner) }
				</Typography>
			</Paper>
		
    
};